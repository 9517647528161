// @flow

import React from "react";
import {
    DefaultLayout,
    Layout,
    withPrivateRoute,
    DeadlineExtensionReasons,
} from "@containers";

/**
 * Keuzelijst uitstel OV/DV
 */
const OptionListPostponement = (props: *) => (
    <Layout {...props}>
        <DefaultLayout title="Keuzelijst uitstel OV/DV">
            <DeadlineExtensionReasons />
        </DefaultLayout>
    </Layout>
);

export default withPrivateRoute(OptionListPostponement);
